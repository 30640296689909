<template>
    <div>
        <v-icon v-if="job.state === 'WAITING' || job.state === 'QUEUED'" color="#F9A825">mdi-pause-circle</v-icon>
        <v-icon v-if="job.state === 'RUNNING'" color="#2962FF">mdi-arrow-right-drop-circle</v-icon>
        <v-icon v-if="job.state === 'FAILED' || job.state === 'CANCELLED'" color="#B71C1C">mdi-alert</v-icon>
        <v-icon v-if="job.state === 'FINISHED'" color="#2E7D32">mdi-check-circle</v-icon>
        {{ job.type }}
    </div>
</template>

<script>
export default {
    name: "JobHeader",
    props: {
        job: {
            type: Object,
            default: () => ({})
        }
    },
}
</script>
