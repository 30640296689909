<template>
<!--    <v-container fluid fill-height style="justify-content: center">-->
        <div>
            <alert ref="alertComponent"></alert>
            <v-row align="center" justify="space-between" style="margin: 40px 10px">
                <JobHeader v-for="job in pipeline.jobs" :key="job.id" :job="job" @click.native="showJob(job.id)" style="cursor: pointer"></JobHeader>
            </v-row>
            <v-card class="mb-12" style="min-height: calc(100vh - 200px);">
                <p v-for="log in logs" :key="log" class="log"> {{ log }} </p>
            </v-card>
        </div>
<!--    </v-container>-->
</template>

<script>
import Alert from "../../components/Alert.component";
import JobHeader from "@/components/JobHeader";
import Services from "@/services/api";

export default {
    name: "Pipeline",
    components: {Alert, JobHeader},
    data: () => ({
        id: null,
        pipeline: {jobs: []},
        activeJobId: 0,
        refreshLogThread: null,
        currentLogLine: 0,
        logRefreshFunction: null,
        logs: []
    }),
    async mounted() {
        this.id = this.$route.params.id;
        try {
            await this.refreshPipeline();
            this.activeJobId = this.pipeline.jobs[0].id;
            this.initiateLogs();
        } catch (e) {
            console.error(e);
            this.$refs.alertComponent.showErrorMessage(e.message);
        }
    },
    computed: {
        getActiveJob() {
            return this.pipeline.jobs.find(j => j.id === this.activeJobId);
        }
    },
    methods: {
        async refreshPipeline() {
            this.pipeline = await Services.Pipelines.getOnePipeline(this.id);
        },
        initiateLogs() {
            // handle logs refresh
            this.currentLogLine = 0;
            this.logs = [];
            this.accumulateLogs();
        },
        accumulateLogs() {
            Services.Pipelines.getJobsLogs({
                pipelineId: this.pipeline.id,
                jobId: this.activeJobId,
                startLine: this.currentLogLine
            })
                .then(({currentLine, endOfFile, logs}) => {
                    this.addLogs(logs, currentLine);

                    // no need for logs refresh if pipeline is finished
                    if (endOfFile && (this.getActiveJob.state === "FINISHED" || this.getActiveJob.state === "FAILED")) return;

                    let netWaitingTime = 100;

                    // refresh pipeline to detect ended jobs
                    if (endOfFile) {
                        this.refreshPipeline();
                        netWaitingTime = 5000;
                    }

                    // recursive call
                    this.refreshLogThread = setTimeout(() => this.accumulateLogs(), netWaitingTime);
                })
        },
        addLogs(logs, currentLine) {
            this.logs = this.logs.concat(logs);
            this.currentLogLine = currentLine;
            console.log("this.logs = ", this.logs);

            // TODO : scroll to bottom if already at the bottom of the page
        },
        showJob(id) {
            if (this.activeJobId.id !== id) {
                console.log('choosing id :', id);
                clearTimeout(this.refreshLogThread)
                this.activeJobId = id;
                this.initiateLogs();
            }
        }
    },
    beforeDestroy() {
        clearTimeout(this.refreshLogThread)
    }
}
</script>

<style scoped>
.log {
    white-space: pre-wrap;
    margin-bottom: 0;
    font-family: monospace;
}
</style>